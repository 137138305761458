<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <span>
        {{
          formData && formData.channelType
            ? $t("edit_notification") + " - " + formData.channelType
            : $t("add_notification")
        }}
      </span>
      <v-spacer />
      <v-icon @click="$emit('cancel')">mdi-close</v-icon>
    </v-toolbar>

    <v-form ref="form" class="pa-6">
      <v-row>
        <v-select
          :label="$t('channelType')"
          v-model="formData.channelType"
          :items="channelTypes"
          item-text="name"
          item-value="id"
          class="test-locator-datasetId"
          :no-data-text="$t('no_data')"
          :rules="formRules.required"
          :autofocus="true"
        >
          <template v-slot:item="{ item }">
            <span :label="item.name">{{ item.name }}</span>
          </template>
        </v-select>
      </v-row>
      <v-row>
        <v-select
          :label="$t('templateType')"
          v-model="formData.templateType"
          :items="templateTypes"
          item-text="name"
          item-value="id"
          class="test-locator-datasetId"
          :no-data-text="$t('no_data')"
          :rules="formRules.required"
        >
          <template v-slot:item="{ item }">
            <span :label="item.name">{{ item.name }}</span>
          </template>
        </v-select>
      </v-row>
      <v-row>
        <GsNotificationsSettings
          :label="$t('email_notification_template')"
          v-model="formData.settings"
          :notificationTemplateType="formData.templateType"
          :required="true"
          ref="notifications_settings"
        />
      </v-row>
      <v-row>
        <GsObjectEditor
          :label="$t('inputParams')"
          v-model="formData.inputParams"
          :required="true"
        />
      </v-row>
    </v-form>

    <!-- errors -->
    <div class="d-flex flex-row px-3" v-if="error">
      <span class="subtitle-1 mb-4 red--text">
        {{ $t("form_invalid_text") }}: {{ error }}
      </span>
    </div>

    <v-card-actions>
      <!-- <v-btn class="mr-2" color="default" @click="$emit('cancel')">
        {{ $t("close") }}
      </v-btn> -->
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="save">
        {{ $t("save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const notificationsEnums = require("@/app-data/notifications-enums.json");
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsObjectEditor from "@/components/GsObjectEditor";
import GsNotificationsSettings from "@/components/GsNotificationsSettings";

export default {
  name: "GsNotificationEditor",
  components: {
    GsObjectEditor,
    GsNotificationsSettings,
  },
  props: {},
  data() {
    return {
      formData: {
        channelType: null,
        templateType: null,
        settings: null,
        inputParams: null,
      },
      formRules: {
        required: [
          (value) =>
            typeof value === "undefined" || value == null || value == ""
              ? this.$t("field_required")
              : true,
        ],
        requiredIfCreate: [
          (value) =>
            typeof value === "undefined" || value == null || value == ""
              ? this.$t("field_required")
              : true,
        ],
        requiredArray: [
          (value) =>
            typeof value === "undefined" ||
            value == null ||
            value == "" ||
            (Array.isArray(value) && value.length == 0)
              ? this.$t("field_required")
              : true,
        ],
        optional: [(value) => true],
      },
      channelTypes: [
        {
          id: notificationsEnums.notificationChannelType,
          name: notificationsEnums.notificationChannelType,
        },
      ],
      templateTypes: [
        {
          id: notificationsEnums.notificationTemplateType,
          name: notificationsEnums.notificationTemplateType,
        },
      ],
      valid: true,
      error: null,
      loading: false,
    };
  },
  async created() {},
  methods: {
    async create() {
      this.clearForm();
    },
    async edit(notificationId) {
      this.clearForm();
      if (typeof notificationId == "string") {
        this.formData = await this.getNotification(notificationId);
      } else {
        this.formData = JSON.parse(JSON.stringify(notificationId));
      }
    },
    async getNotification(id) {
      try {
        const result = await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/notifications/${id}`,
          method: "GET",
        });
        return result.data;
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async save() {
      this.error = null;

      const valid1 = this.$refs.form.validate();
      const valid2 = this.$refs.notifications_settings.validate();
      this.valid = valid1 && valid2;
      // console.log(valid1, valid2)
      if (!this.valid) {
        this.$dialog.error({
          title: this.$t("form_invalid_title"),
          text: this.$t("form_invalid_text"),
          actions: {
            true: this.$t("close"),
          },
        });
        return;
      }

      const url = this.formData.notificationId
        ? `${process.env.VUE_APP_BFF_ORIGIN}/notifications/${this.formData.notificationId}`
        : `${process.env.VUE_APP_BFF_ORIGIN}/notifications`;

      const method = this.formData.notificationId ? "PATCH" : "POST";

      const data = {
        channelType: this.formData.channelType,
        templateType: this.formData.templateType,
        settings: this.formData.settings,
        inputParams: this.formData.inputParams,
      };

      try {
        this.loading = true;
        const result = await callAPI({ url, method, data });
        this.$emit("saved", result.data);
        this.clearForm();
      } catch (err) {
        const data = err?.response?.data;
        // console.log(data);

        if (data.statusCode === 400) {
          // this.error = this.$t(data.message)
          this.error = this.$t(data.validation.keys.join(", "));
        } else {
          this.errorSnackbar(err);
        }
      } finally {
        this.loading = false;
      }
    },
    clearForm() {
      this.$refs.form.resetValidation();
      this.$refs.notifications_settings.resetValidation();
      this.valid = true;
      this.formData = {
        channelType: null,
        templateType: null,
        settings: null,
        inputParams: {},
      };
      this.error = null;
    },
  },
};
</script>
