<template>
  <v-textarea
    :label="label"
    :value="textValue"
    rows="3"
    @input="onChange"
    :rules="[validateJson]"
  ></v-textarea>
</template>

<script>
export default {
  name: "GsObjectEditor",
  components: {},
  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: Function,
      default: () => true,
    },
  },
  data: () => ({
    textValue: null,
    rules: [(value) => this.validateJson(value)],
  }),
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.setTextValueFromValue();
      },
    },
  },
  methods: {
    onChange(newTextValue) {
      // console.log(newTextValue)
      if (this.textValue == newTextValue) return;
      this.textValue = newTextValue;
      this.emitValueFromTextValue();
    },
    setTextValueFromValue() {
      const newTextValue = JSON.stringify(this.value);
      if (this.textValue == newTextValue) return;
      this.textValue = newTextValue;
    },
    emitValueFromTextValue() {
      let value = null;
      try {
        value = JSON.parse(this.textValue);
        this.$emit("input", value);
        // console.log("GsObjectEditor: emit", value)
        return true;
      } catch (error) {
        // this.errorSnackbar(error);
        return false;
      }
    },
    validateJson(json) {
      if (this.required) {
        if (json === "null" || json === "") return this.$t("field_required");
      }
      if (json === "null" || json === "") return true;

      let obj = null;
      try {
        obj = JSON.parse(json);
      } catch {
        return this.$t("invalid_json_format");
      }

      let validateError = this.validator(obj);
      if (validateError !== true) {
        return validateError;
      }
      return true;
    },
  },
};
</script>

<style scoped></style>
