<template>
  <v-container fluid>
    <v-select
      :items="visibleTemplates"
      item-value="templateId"
      :value="value"
      @change="onChange"
      :no-data-text="$t('no_data')"
    >
      <template v-slot:selection="{ item }">
        <span>{{ item.name }}</span>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{ $t(item.locale) }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-select>
  </v-container>
</template>

<script>
export default {
  name: "AvailableNotificationTemplatesDropdown",
  components: {},
  props: {
    value: {
      type: Object,
      default: null,
    },
    templates: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      totalItems: 0,
    };
  },
  computed: {
    visibleTemplates() {
      return this.templates.filter(this.filter);
    },
  },
  watch: {},
  async created() {},
  methods: {
    onChange(value) {
      const selectedTemplate = this.templates.find(
        (p) => p.templateId === value
      );
      this.$emit("input", selectedTemplate);
    },
  },
};
</script>
