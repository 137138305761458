<template>
  <v-container fluid class="pa-6">
    <!-- toolbar -->
    <v-row>
      <v-btn dark color="primary" @click="createNotification">
        <v-icon small class="mr-2">mdi mdi-plus</v-icon>
        {{ $t("add") }}
      </v-btn>
    </v-row>
    <!-- filter panel -->
    <v-row>
      <GsDataFilter
        ref="dataFilter"
        :appSetting="appSettingKey"
        :filters="filters"
        v-on:changed="onFilterChanged"
      />
    </v-row>
    <!-- table -->
    <v-row>
      <v-container fluid class="ma-0 pa-0">
        <v-card>
          <GsDataTable
            ref="table"
            api=""
            endpoint="/notifications"
            :appSetting="appSettingKey"
            :headers="headers"
            :sort-by="['templateType']"
            :sort-desc="[false]"
            :beforeCallApi="beforeCallApi"
            :afterCallApi="afterCallApi"
          >
            <template v-slot:item.actions="{ item }">
              <GsActionsMenu
                :actions="actions"
                :onActivate="(actionId) => action_activate(actionId, item)"
              />
            </template>

            <template v-slot:item.template_type="{ item }">
              <strong class="col-template_type">{{
                item.template_type
              }}</strong>
            </template>

            <template v-slot:item.channel_type="{ item }">
              <strong class="col-channel_type">{{ item.channel_type }}</strong>
            </template>

            <template v-slot:item.settings="{ item }">
              <div style="font-size: 10px">
                {{ item.settings | shortenText }}
              </div>
            </template>

            <template v-slot:item.inputParams="{ item }">
              <div style="font-size: 10px">
                {{ item.inputParams | shortenText }}
              </div>
            </template>

            <template v-slot:item.createdAt="{ item }">
              <span>
                {{ item.createdAt | getDisplayDate }}
                <br />
                ({{ item.$calculated.$createdBy | empty }})
              </span>
            </template>

            <template v-slot:item.updatedAt="{ item }">
              <span v-if="item.updatedAt">
                {{ item.updatedAt | getDisplayDate }}
                <br />
                ({{ item.$calculated.$updatedBy | empty }})
              </span>
            </template>

            <!-- <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-4 my-2"
                    @click="editNotification(item)"
                    v-bind="attrs"
                    v-on="on"
                    >mdi mdi-pencil</v-icon
                  >
                </template>
                <span>{{ $t("edit") }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-4 my-2"
                    @click="deleteNotification(item)"
                    v-bind="attrs"
                    v-on="on"
                    >mdi mdi-delete</v-icon
                  >
                </template>
                <span>{{ $t("delete") }}</span>
              </v-tooltip>
            </template> -->
            <template v-slot:footer.page-text="props">
              {{ props.pageStart }} - {{ props.pageStop }} /
              {{ props.itemsLength }}
            </template>
          </GsDataTable>
        </v-card>
      </v-container>
    </v-row>
    <!-- add/edit notifications -->
    <v-dialog
      v-model="dialogEditNotificationsVisible"
      persistent
      max-width="600px"
    >
      <GsNotificationEditor
        ref="notificationEditor"
        @saved="modalSave"
        @cancel="dialogEditNotificationsVisible = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsNotificationEditor from "@/components/GsNotificationEditor";
import { GsDataTable, GsActionsMenu, GsDataFilter } from "ngt-frontend-core";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Notifications",
  components: {
    GsNotificationEditor,
    GsDataTable,
    GsActionsMenu,
    GsDataFilter,
  },
  data() {
    return {
      appSettingKey: "notifications",
      headers: [
        {
          text: this.$t("actions"),
          value: "actions",
          width: 100,
          sortable: false,
        },
        {
          text: this.$t("template_type"),
          value: "templateType",
          width: 200,
          sortable: true,
        },
        {
          text: this.$t("channel_type"),
          value: "channelType",
          width: 120,
          sortable: true,
        },
        {
          text: this.$t("settings"),
          value: "settings",
          width: 120,
          sortable: false,
        },
        {
          text: this.$t("inputParams"),
          value: "inputParams",
          width: 160,
          sortable: false,
        },
        {
          text: this.$t("createdAt"),
          value: "createdAt",
          width: 120,
          sortable: true,
        },
        {
          text: this.$t("updatedAt"),
          value: "updatedAt",
          width: 140,
          sortable: true,
        },
      ],
      dialogEditNotificationsVisible: false,
      filters: [
        {
          field: "createdBy",
          component: "GsSelectFilter",
          params: {
            options: [],
          },
        },
      ],
      initialized: false,
      actions: ["edit", "delete"],
    };
  },
  async mounted() {
    this.initialized = false;
    await this.fillCreatedByOptions();
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  methods: {
    async refreshTable() {
      if (!this.$refs.table) return;
      try {
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      // params.url += `&ownedBy=${encodeURIComponent(
      //   this.$store.state.user.ownedBy
      // )}`;
      params.url += this.$refs.dataFilter.getFilter();
      params.url += "&r8sFields=createdBy.name,updatedBy.name";
      return params;
    },
    async afterCallApi(params) {
      for (const item of params.items) {
        item.$calculated = item.$calculated || {};
        item.$calculated.$createdBy = item?.createdBy?.name || "";
        item.$calculated.$updatedBy = item?.updatedBy?.name || "";
      }
      return params;
    },
    async createNotification() {
      this.dialogEditNotificationsVisible = true;
      this.$nextTick(async () => {
        if (!this.$refs.notificationEditor) return;
        await this.$refs.notificationEditor.create();
      });
    },
    async edit_click(notification) {
      this.dialogEditNotificationsVisible = true;
      this.$nextTick(async () => {
        if (!this.$refs.notificationEditor) return;
        await this.$refs.notificationEditor.edit(notification);
      });
    },
    async modalSave() {
      this.dialogEditNotificationsVisible = false;
      await this.refreshTable();
    },
    async delete_click(notification) {
      const confirmText = `${this.$t("delete_modal")}\n\n${
        notification.notificationId
      }`;
      const confirmResult = confirm(confirmText);
      if (!confirmResult) return;

      const url = `${process.env.VUE_APP_BFF_ORIGIN}/notifications/${notification.notificationId}`;
      const result = await callAPI({ url, method: "DELETE" });
      if (result.status !== 204) return;
      await this.refreshTable();
    },
    action_activate(actionId, item) {
      switch (actionId) {
        case "edit":
          this.edit_click(item);
          break;
        case "delete":
          this.delete_click(item);
          break;
      }
    },
    onFilterChanged() {
      this.$refs.table.goToFirstPage();
    },
    async fillCreatedByOptions() {
      const data = await this.getUsers();
      if (!data) return;
      this.filters.find((p) => p.field === "createdBy").params.options =
        data.map((p) => ({ value: p._uri, text: p.name }));
    },
  },
};
</script>

// INSERT DUMMY DATA // const url =
`${process.env.VUE_APP_BFF_ORIGIN}/notifications` // const data = { //
channelType: 'mail', // templateType: 'plain', // settings: { "locales": [{
"locale": "hu_HU", "templateId": "ec6c5ee2-c79a-4aeb-b462-547ffff000a2" }] }, //
inputParams: { "locale": { "type": "single-dropdown" } } // } // callAPI({ //
url, // method: 'POST', // data // })
