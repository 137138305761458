<template>
  <div class="my-3" style="width: 100%">
    <span class="caption">{{ label }}</span>
    <v-card outlined class="mb-1">
      <div class="d-flex flex-row align-center pr-3">
        <GsAvailableNotificationTemplatesDropdown
          v-model="selectedTemplate"
          :templateType="notificationTemplateType"
          :templates="templates"
          :filter="templatesFilter"
        />
        <v-btn
          class="ml-4 white--text"
          small
          color="primary"
          @click="addTemplate"
          :disabled="selectedTemplate == null"
        >
          <v-icon left small>mdi mdi-plus</v-icon>
          {{ $t("add") }}
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <v-data-table
        :headers="headers"
        :items="locales"
        :footer-props="{
          itemsPerPageText: $t('rows_per_page'),
          pageText: '{0}-{1} / {2}',
        }"
        :loading-text="$t('loading_data')"
        :no-data-text="$t('no_data')"
        :no-results-text="$t('no_result')"
      >
        <template v-slot:item.locale="{ item }">
          <span>{{ $t(item.locale) }}</span>
        </template>
        <template v-slot:item.subject="{ item }">
          <div>
            <!-- <v-avatar size="16px" class="mx-2">
							<v-img :src="item.$calculated.icon" contain />
						</v-avatar> -->
            <span>{{ getTemplateSubject(item.templateId) }}</span>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="removeTemplate(item)"
            >mdi mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </v-card>
    <div class="v-text-field__details" v-if="!valid">
      <div class="v-messages theme--light error--text" role="alert">
        <div class="v-messages__wrapper">
          <div class="v-messages__message">This field is required!</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GsAvailableNotificationTemplatesDropdown from "@/components/GsAvailableNotificationTemplatesDropdown";
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;
const notificationsEnums = require("@/app-data/notifications-enums.json");
import dayjs from "dayjs";

export default {
  name: "GsNotificationsSettings",
  components: {
    GsAvailableNotificationTemplatesDropdown,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    notificationTemplateType: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      templates: [],
      editValue: null,
      selectedTemplate: null,
      headers: [
        { text: this.$t("subject"), align: "start", value: "subject" },
        { text: this.$t("locale"), align: "start", value: "locale" },
        { text: this.$t("actions"), value: "actions" },
      ],
      valid: true,
    };
  },
  computed: {
    locales() {
      return this.editValue ? this.editValue.locales : [];
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        this.editValue = value;
      },
    },
    notificationTemplateType() {
      this.getTemplates();
    },
  },
  async created() {
    await this.getTemplates();
  },
  methods: {
    async getTemplates() {
      if (this.notificationTemplateType == null) {
        this.templates = [];
        this.totalItems = 0;
        return;
      }

      try {
        this.$store.state.loading = true;
        // let url = `/templates?page=0&limit=10&sort=name:asc&filter=status:eq:${notificationsEnums.notificationTemplateStatus},type:eq:${this.notificationTemplateType}`;
        // console.log(this.notificationTemplateType)
        let url = `/resource/mailGateway/templates?page=0&limit=10&sort=name:asc&filter=status:eq:${notificationsEnums.notificationTemplateStatus},type:eq:${this.notificationTemplateType}`;
        const result = await callBffAPI({
          url,
          method: "GET",
        });
        this.templates = [];
        for (const template of result.data) {
          await this.calculateProperties(template);
          this.templates.push(template);
        }
        this.totalItems = parseInt(result.headers["x-total-count"], 10);
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.state.loading = false;
      }
    },
    async calculateProperties(template) {
      template.$calculated = template.$calculated || {};
    },
    getDisplayDate(date) {
      if (!date) {
        return "";
      }
      return dayjs(date).format("YYYY.MM.DD HH:MM:SS");
    },
    getTemplateSubject(templateId) {
      const template = this.templates.find((p) => p.templateId == templateId);
      if (template == null) return "";
      return template.subject;
    },
    async addTemplate() {
      if (this.editValue == null) {
        this.editValue = {
          locales: [],
        };
      }
      this.editValue.locales.push({
        locale: this.selectedTemplate.locale,
        templateId: this.selectedTemplate.templateId,
      });
      this.emitValue();
    },
    async removeTemplate(template) {
      this.editValue.locales = this.locales.filter(
        (p) => p.templateId !== template.templateId
      );
      this.emitValue();
    },
    templatesFilter(template) {
      return !this.locales.some((p) => p.templateId === template.templateId);
    },
    emitValue() {
      this.validate();
      this.$emit("input", this.editValue);
    },
    validate() {
      this.valid =
        !this.required ||
        (typeof this.editValue === "object" &&
          this.editValue !== null &&
          this.editValue.locales.length > 0);
      return this.valid;
    },
    resetValidation() {
      this.valid = true;
    },
  },
};
</script>
